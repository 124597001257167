import { BsCheck, BsX } from 'react-icons/bs';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { ContactStatus, ContactType, MemberType } from '@qwealth/qdata';
import { ComplianceChecklistStatus } from 'data/models/Compliance';

export type TagTitle =
  | ContactType
  | ContactStatus
  | ComplianceChecklistStatus
  | MemberType
  | 'Power of Attorney'
  | 'Authorized Individual'
  | 'Investment Client'
  | 'Not Investment Client'
  | 'Foreign Primary Address'
  | 'KYC Reminder'
  | 'KYC Warning'
  | 'Household'
  | 'Individual'
  | 'Corporate'
  | 'Compliance Approved'
  | 'Not Compliance Approved'
  | 'Re-Review Pending';

type TagColor = 'Green' | 'Blue' | 'Red' | 'Gray' | 'Light Gray';

type TagIcons = 'Check Mark' | 'Warning' | 'X';

const tagColorMap: Record<TagTitle, TagColor> = {
  Active: 'Green',
  Inactive: 'Red',
  Deceased: 'Red',
  'Former Client': 'Red',
  'Investment Client': 'Blue',
  'Not Investment Client': 'Gray',
  'Foreign Primary Address': 'Red',
  'KYC Reminder': 'Gray',
  'KYC Warning': 'Red',
  'Primary Client': 'Light Gray',
  'Secondary Client': 'Light Gray',
  'Additional Client': 'Light Gray',
  Lead: 'Blue',
  'Household Member': 'Blue',
  'Related Contact': 'Blue',
  'Power of Attorney': 'Blue',
  Beneficiary: 'Blue',
  'Non-Client Spouse': 'Blue',
  'Authorized Individual': 'Blue',
  Dependant: 'Blue',
  Household: 'Blue',
  Individual: 'Blue',
  Corporate: 'Blue',
  Complete: 'Green',
  'Not Started': 'Gray',
  'Advisor Action Required': 'Red',
  'Re-review Required': 'Blue',
  'Compliance Approved': 'Blue',
  'Not Compliance Approved': 'Red',
  'Re-Review Pending': 'Blue',
};

interface InfoTagProps {
  tagTitle: TagTitle;
  fontWeight?: 'Default' | 'Thin' | 'Bold';
  icon?: TagIcons;
  numberToDisplay?: number;
  toolTipMessage?: string;
  overrideColor?: TagColor;
  removePadding?: boolean;
}

const InfoTag: React.FC<InfoTagProps> = ({
  tagTitle,
  fontWeight,
  icon,
  numberToDisplay,
  toolTipMessage,
  overrideColor,
  removePadding,
}) => {
  const status = overrideColor ? overrideColor : tagColorMap[tagTitle];

  const getTagTitle = (): string => {
    switch (tagTitle) {
      case 'KYC Reminder':
        return `KYC: ${numberToDisplay?.toString()} Days Remaining`;
      case 'KYC Warning':
        // Ensure numberToDisplay is defined and convert to positive: (numberToDisplay ?? 0) * -1)
        return `KYC: ${((numberToDisplay ?? 0) * -1).toString()} Days Overdue`;
      default:
        return tagTitle;
    }
  };

  const getFontWeightForTagTitle = () => {
    switch (fontWeight) {
      case 'Default':
        return themeGet('fontWeights.default');
      case 'Thin':
        return themeGet('fontWeights.thin');
      default:
        return themeGet('fontWeights.bold');
    }
  };

  const getPaddingForTagTitle = () => {
    if (
      [
        'KYC Reminder',
        'KYC Warning',
        'Primary Client',
        'Secondary Client',
        'Additional Client',
      ].includes(tagTitle) ||
      removePadding
    ) {
      return themeGet('space.none');
    }
    return themeGet('space.small');
  };

  const getColorForStatus = () => {
    switch (status) {
      case 'Green':
        return themeGet('colors.green');
      case 'Blue':
        return themeGet('colors.blue');
      case 'Red':
        return themeGet('colors.red');
      case 'Gray':
        return themeGet('colors.gray.1');
      case 'Light Gray':
        return themeGet('colors.gray.0');
    }
  };

  const getBackgroundColorForStatus = () => {
    switch (status) {
      case 'Green':
        return themeGet('colors.greenTransparent');
      case 'Blue':
        return themeGet('colors.blueTransparent');
      case 'Red':
        return themeGet('colors.redTransparent');
      case 'Gray':
        return themeGet('colors.blueTransparent');
      case 'Light Gray':
        return themeGet('colors.gray.4');
    }
  };

  const InfoTag = styled(Box)`
    background-color: ${getBackgroundColorForStatus()};
    color: ${getColorForStatus()};
    border-radius: ${themeGet('radii.default')};
    padding: ${getPaddingForTagTitle()};
    padding-left: ${themeGet('space.default')};
    padding-right: ${themeGet('space.default')};
    font-size: ${themeGet('fontSizes.default')};
    font-weight: ${getFontWeightForTagTitle()};
    width: fit-content;
  `;

  return (
    <InfoTag>
      {getTagTitle()}
      {icon === 'Check Mark' && tagTitle !== 'Not Investment Client' && <BsCheck size="20px" />}
      {icon === 'X' && <BsX size="20px" />}
    </InfoTag>
  );
};

export default InfoTag;
