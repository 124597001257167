let envPrefix = '';
if (window.location.href.includes('dev') || window.location.hostname === 'localhost') {
  envPrefix = 'dev.';
} else if (window.location.href.includes('staging')) {
  envPrefix = 'staging.';
}

export const DIGIT_URL = 'https://qwealth.d1g1t.com';
export const Q_CASH_URL = `https://${envPrefix}qcash.qwnext.com`;
export const Q_FOLIO_URL = `https://${envPrefix}qfolio.qwnext.com`;
export const Q_LIFE_URL = `https://${envPrefix}qlife.qwnext.com`;
export const Q_CONNECT_URL = `https://${envPrefix}qconnect.qwnext.com`;
export const SF_URL = 'https://qwealth.my.salesforce.com/';
export const CONQUEST_DASHBOARD_PATH = '/new-planning/:conquestPlanID/dashboard';
export const Q_NET_URL = 'https://quintessencewealth.sharepoint.com';
export const Q_FEEDBACK_URL = 'https://qfeedback.qwealth.com/';
export const Q_ISPRING_URL =
  'https://qwealth.ispring.com/app/user-portal/knowledge-base/content/0c258893-df12-11ef-bf9e-c6a569e32ef3';

export const TICKET_LABEL = 'tickets';
export const RELATED_CONTACTS_LABEL = 'related contacts';
export const MEETING_NOTES_LABEL = 'meeting notes';
export const OPPORTUNITIES_LABEL = 'opportunities';
export const AUTO_TRANSACTIONS_LABEL = 'auto transactions';
export const STICKY_NOTES_LABEL = 'sticky notes';
export const QNOTES_LABEL = 'qNotes';

export const QFEED_TABS = {
  ACTIVITY: 'Activities',
  BIRTHDAYS: 'Birthdays',
};

export const TRAPEZOID = 'trapezoid';

export const TODAY = 'Today';
export const NEXT_7_DAYS = 'Next 7 days';
