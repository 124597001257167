import axios from 'axios';
import { Dispatch } from 'redux';

import { responseListMapper, responseEntityMapper } from '@qwealth/qcore';
import { FeesRecord, FeeReviewsQueryParams, FeesSchedule } from '@qwealth/qdata';

import { REACT_APP_QWEALTH_API, errorHandler } from 'services/axiosService';
import { sortTiersByTierOrder } from 'data/helpers/feeSchedule';

export const fetchFeeReviews = async (params: FeeReviewsQueryParams = {}): Promise<FeesRecord[]> => {
  const response = await axios.get(`${REACT_APP_QWEALTH_API}/fees/current/`, { params });
  const feesReviews = responseListMapper<FeesRecord>(response);
  return feesReviews;
};

export const fetchFeesSchedule = async (feesScheduleId: number, dispatch: Dispatch): Promise<FeesSchedule> =>
  axios
    .get(`${REACT_APP_QWEALTH_API}/fees/schedules/${feesScheduleId}`)
    .then(resp => {
      const feesSchedule = responseEntityMapper<FeesSchedule>(resp);
      feesSchedule.feeTier = sortTiersByTierOrder(feesSchedule.feeTier);
      return feesSchedule
    })
    .catch(errorHandler(dispatch, `API Error. Failed fetching Fees Schedule with ID: ${feesScheduleId}`));
