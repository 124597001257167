import React, { useState } from 'react';

import { Box } from '@qwealth/qcore';

import { ComplianceQuestion, ShortCode } from 'data/models/Compliance';
import SectionQuestion from './SectionQuestion';
import ChecklistSectionNotes from './ChecklistSectionNotes';
import SectionQuestionWithSubquestions from './SectionQuestionWithSubquestions';
import { useAppSelector } from 'data/store';
import { selectComplianceSelectedChecklistNotes } from 'data/selectors/compliance';

interface ChecklistSectionProps {
  section: ComplianceQuestion;
  updateQuestionAnswer: (questionId: string, option: string) => void;
}

const ChecklistSection: React.FC<ChecklistSectionProps> = ({ section, updateQuestionAnswer }) => {
  const [numNoncompliant, setNumNonCompliant] = useState<number>(0);
  const selectedNotes = useAppSelector(selectComplianceSelectedChecklistNotes);

  const incrementNonCompliance = (increment: 1 | -1) => {
    setNumNonCompliant(numNoncompliant + increment);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="default"
      padding="large"
      backgroundColor="gray.3"
      borderRadius="default"
    >
      <Box display="flex" flexDirection="row" fontWeight="bold" fontSize="large" gap="default">
        {section?.text}
      </Box>
      {section?.children &&
        section.children.map((question, index) => {
          return (
            <Box
              key={question.text + index.toString()}
              display="flex"
              flexDirection="column"
              gap="default"
            >
              {question.children ? (
                <SectionQuestionWithSubquestions
                  question={question}
                  isCompliant={true}
                  incrementNonCompliance={incrementNonCompliance}
                  updateQuestionAnswer={updateQuestionAnswer}
                />
              ) : (
                <SectionQuestion
                  question={question}
                  isCompliant={true}
                  incrementNonCompliance={incrementNonCompliance}
                  updateQuestionAnswer={updateQuestionAnswer}
                />
              )}
            </Box>
          );
        })}

      {numNoncompliant > 0 && (
        <ChecklistSectionNotes
          sectionId={section.id}
          sectionTitle={section.text}
          note={selectedNotes && selectedNotes[section.id] ? selectedNotes[section.id].content : ''}
        />
      )}
    </Box>
  );
};

export default ChecklistSection;
