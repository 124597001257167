import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { Box, SelectInput2 } from '@qwealth/qcore';

import { useAppSelector } from 'data/store';
import { selectComplianceContact, selectComplianceHousehold } from 'data/selectors/compliance';
import CognitoWrappedClientQVault from 'components/common/CognitoWrappedClientQVault';

type qvaultOption = {
  name: string;
  qid: string;
};

const ComplianceQVault: FC = (): JSX.Element => {
  const selectedContact = useAppSelector(selectComplianceContact);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const [selectedQVaultOption, setSelectedQVaultOption] = useState<qvaultOption>();
  const [isAdvisor, setIsAdvisor] = useState<boolean>(true);
  const [qvaultOptions, setQVaultOptions] = useState<qvaultOption[]>([]);

  useEffect(() => {
    setSelectedQVaultOption({
      name: selectedContact?.name as string,
      qid: selectedContact?.QID as string,
    });

    const householdOption = [
      {
        name: selectedHousehold?.name,
        qid: selectedHousehold?.QID,
      } as qvaultOption,
    ];
    const memberOptions = selectedHousehold?.members.map(member => {
      return {
        name: selectedContact?.name,
        qid: member.QID,
      } as qvaultOption;
    });

    if (memberOptions) {
      const allOptions = householdOption.concat(memberOptions);
      setQVaultOptions(allOptions);
    }
  }, [selectedContact]);

  const changeQVault = (qid: string | number | undefined) => {
    const newOption = qvaultOptions.find(option => {
      return option.qid === qid;
    });
    if (newOption) {
      setSelectedQVaultOption(newOption);
    }
  };

  return (
    <Box width="100%" overflowY="scroll" display="flex" flexDirection="column" gap="default">
      <Box display="flex" flexDirection="row" alignItems="center" gap="large">
        Directory
        <SelectInput2
          data={qvaultOptions}
          onChange={qid => changeQVault(qid)}
          value={selectedQVaultOption?.qid}
          toLabel={opt => opt?.name}
          toValue={opt => opt?.qid}
        />
        Client View
        <Form.Check
          id="isAdvisor"
          type="switch"
          checked={!isAdvisor}
          onChange={() => {
            setIsAdvisor(prevState => !prevState);
          }}
        />
      </Box>

      <CognitoWrappedClientQVault
        qid={selectedQVaultOption?.qid ?? ''}
        name={selectedQVaultOption?.name ?? ''}
        isAdvisor={isAdvisor}
      />
    </Box>
  );
};

export default ComplianceQVault;
