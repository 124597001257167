import {
  ComplianceContact,
  ComplianceHousehold,
  ComplianceNotesMap,
  ComplianceQuestion,
  ContactChecklist,
} from 'data/models/Compliance';
import { RootState } from 'data/store';
import { createSelector } from 'reselect';

export const selectComplianceHouseholds = (state: RootState): ComplianceHousehold[] => {
  return state.compliance.households;
};

export const selectComplianceHousehold = (state: RootState): ComplianceHousehold | undefined => {
  return state.compliance.selectedHousehold;
};

export const selectComplianceContact = (state: RootState): ComplianceContact | undefined => {
  return state.compliance.selectedContact;
};

export const selectComplianceCheckslists = (state: RootState): ContactChecklist[] => {
  const checklists = state.compliance.checklists;
  if (checklists) {
    return checklists;
  }
  return [];
};

export const selectComplianceSelectedCheckslist = (state: RootState): ComplianceQuestion[] => {
  const checklist = state.compliance.selectedChecklist;
  if (checklist) {
    return checklist;
  }
  return [];
};

export const selectComplianceSelectedCheckslistNotes = (
  state: RootState,
): ComplianceNotesMap | undefined => {
  return state.compliance.SelectedChecklistNotes;
};

export const selectHouseholds = createSelector(
  selectComplianceHouseholds,
  (households: ComplianceHousehold[]) => households,
);

export const selectComplianceSelectedHousehold = createSelector(
  selectComplianceHousehold,
  (household: ComplianceHousehold | undefined) => {
    household;
  },
);

export const selectComplianceSelectedContact = createSelector(
  selectComplianceContact,
  (contact: ComplianceContact | undefined) => {
    contact;
  },
);

export const selectComplianceCheckslistsForHousehold = createSelector(
  selectComplianceCheckslists,
  (checklists: ContactChecklist[] | undefined) => {
    return checklists;
  },
);

export const selectComplianceSelectedChecklist = createSelector(
  selectComplianceSelectedCheckslist,
  (checklist: ComplianceQuestion[] | undefined) => {
    return checklist;
  },
);

export const selectComplianceSelectedChecklistNotes = createSelector(
  selectComplianceSelectedCheckslistNotes,
  (checklistNotes: ComplianceNotesMap | undefined) => {
    return checklistNotes;
  },
);
