import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@qwealth/qcore';
import { S3Item } from '@qwealth/qvault';
import { fetchRTQWithContactQID, loadLegalEntity, RTQ } from '@qwealth/qdata';
import {
  AddressesInfoConfirmation,
  BankAccountForm,
  BankingInfoConfirmation,
  defaultBankingInfo,
  EmploymentInfoConfirmation,
  FinancialInfoConfirmation,
  GovernmentIdConfirmation,
  LegalEntityBasicInfo,
  LegalEntityOwnershipInfo,
  RegulatoryInfoConfirmation,
  useAddressInfo,
  useFinancialInfo,
  useGovernmentInfo,
  useRegulatoryInfo,
} from '@qwealth/kyc';

import DemographicInfo from 'components/Contacts/ContactDetails/VitalTab/DemographicInfo';
import CitizenshipAndResidency from 'components/Contacts/ContactDetails/VitalTab/CitizenshipAndResidency';
import { getSectionForShortCode } from 'utils/ComplianceUtil';
import { useAppSelector } from 'data/store';
import { selectComplianceContact, selectComplianceHousehold } from 'data/selectors/compliance';
import { ShortCode } from 'data/models/Compliance';
import { loadSingleContact } from 'data/actions/contacts';
import PMNotesCard from './PMNotesCard';
import Definitions from './Definitions';
import PDFViewer from '../PDFViewer/PdfViewer';
import DocumentSelection from '../PDFViewer/DocumentSelection';
import ComplianceQVault from './ComplianceQVault';

interface ComplianceTasksCardProps {
  taskToDisplay: ShortCode;
  changeTo: (changeTo: ShortCode) => void;
}

const ComplianceTasksCard: FC<ComplianceTasksCardProps> = ({
  taskToDisplay,
  changeTo,
}): JSX.Element => {
  const selectedContact = useAppSelector(selectComplianceContact);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const [isLegalEntity, setIsLegalEntity] = useState<boolean>(false);
  const legalEntity = useAppSelector(state =>
    state.legalEntities && state.legalEntities.legalEntityInitialized
      ? state.legalEntities.legalEntity
      : undefined,
  );
  const { governmentInfo, setGovernmentInfo } = useGovernmentInfo(selectedContact?.QID);
  const { financialInfo, setFinancialInfo } = useFinancialInfo(selectedContact?.QID);
  const [bankingInfo, setBankingInfo] = useState<BankAccountForm>({
    nbinBankAccountDetails: [defaultBankingInfo],
  });
  const { addressesInfo, setAddressesInfo, getAddressFormData } = useAddressInfo(
    selectedContact?.QID as string,
  );
  const { regulatoryInfo, setRegulatoryInfo } = useRegulatoryInfo(selectedContact?.QID as string);
  const [selectedContactRTQ, setSelectedContactRTQ] = useState<RTQ | undefined>();
  const [pdfToDisplay, setPdfToDisplay] = useState<S3Item>();
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section') as ShortCode;
  const dispatch = useDispatch();

  useEffect(() => {
    const rtqLevel = selectedContact?.rtqLevel;
    const isCorporate = rtqLevel === 'Corporate';
    setIsLegalEntity(isCorporate);

    if (rtqLevel && selectedContact) {
      const loadClient = isCorporate ? loadLegalEntity : loadSingleContact;
      dispatch(loadClient(selectedContact.QID));
    }

    getAndSetRTQ();
  }, [selectedContact]);

  useEffect(() => {
    changeTo(section);
    const sectionToScrollTo = getSectionForShortCode(section, isLegalEntity);
    handleScrollTo(sectionToScrollTo as keyof typeof sectionRefs);
  }, [section]);

  const getAndSetRTQ = async () => {
    let rtq: RTQ | undefined = undefined;
    const rtqs = await fetchRTQWithContactQID(selectedContact?.QID as string);

    if (Array.isArray(rtqs) && rtqs.length > 0) {
      rtq = rtqs[0];
    } else if (rtqs !== undefined && !Array.isArray(rtqs)) {
      rtq = rtqs;
    }
    setSelectedContactRTQ(rtq);
  };

  const sectionRefs = {
    GovernmentId: useRef<HTMLDivElement>(null),
    DemographicInfo: useRef<HTMLDivElement>(null),
    LegalEntityInfo: useRef<HTMLDivElement>(null),
    EmploymentInfo: useRef<HTMLDivElement>(null),
    FinancialInfo: useRef<HTMLDivElement>(null),
    Regulatory: useRef<HTMLDivElement>(null),
    PMNotes: useRef<HTMLDivElement>(null),
    RTQResponses: useRef<HTMLDivElement>(null),
    Definitions: useRef<HTMLDivElement>(null),
  };

  const handleScrollTo = (section: keyof typeof sectionRefs) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="50%"
      height="100vh"
      backgroundColor="white"
      padding="large"
      boxShadow="layout"
    >
      <Box
        fontSize="appTitle"
        fontWeight="bold"
        marginBottom="large"
        display="flex"
        flexDirection="column"
      >
        {taskToDisplay}
        <Box height="1px" backgroundColor="gray.0" />
      </Box>
      {taskToDisplay === 'SelectDocument' && (
        <DocumentSelection setPdfToDisplay={setPdfToDisplay} changeTo={changeTo} />
      )}

      {taskToDisplay === 'QVault' && <ComplianceQVault />}

      {taskToDisplay === 'PDFViewer' && (
        <Box minWidth="100%">
          <PDFViewer defaultPdf={pdfToDisplay} />
        </Box>
      )}

      {taskToDisplay === 'All Contact Info' && (
        <Box overflowY="scroll">
          {isLegalEntity && legalEntity ? (
            <Box ref={sectionRefs.LegalEntityInfo}>
              <LegalEntityBasicInfo legalEntity={legalEntity} />
              <LegalEntityOwnershipInfo ownership={legalEntity?.ownership || []} />
            </Box>
          ) : (
            selectedContact?.QID &&
            selectedHousehold?.QID && (
              <Box ref={sectionRefs.DemographicInfo}>
                <DemographicInfo
                  contactQID={selectedContact.QID}
                  householdQID={selectedHousehold.QID}
                  isCompliance={true}
                />
                <CitizenshipAndResidency isEditDisabled={true} contactQID={selectedContact.QID} />
              </Box>
            )
          )}
          <AddressesInfoConfirmation addressesInfo={addressesInfo} />
          <Box ref={sectionRefs.Regulatory}>
            <RegulatoryInfoConfirmation
              regulatoryInfo={regulatoryInfo}
              context={isLegalEntity ? 'Contact' : 'Entity'}
            />
          </Box>
          {!isLegalEntity && (
            <Box>
              <Box ref={sectionRefs.EmploymentInfo}>
                <EmploymentInfoConfirmation contactQid={selectedContact?.QID as string} />
              </Box>
              <Box ref={sectionRefs.GovernmentId}>
                <GovernmentIdConfirmation
                  governmentInfo={governmentInfo}
                  additionalFields={{
                    verificationMethod: { label: 'Verification Method' },
                  }}
                />
              </Box>
            </Box>
          )}

          <Box>
            <BankingInfoConfirmation bankingInfo={bankingInfo} />
          </Box>
          <Box ref={sectionRefs.FinancialInfo}>
            <FinancialInfoConfirmation financialInfo={financialInfo} />
          </Box>
          <Box ref={sectionRefs.PMNotes} marginTop="largest" fontSize="appTitle" fontWeight="bold">
            PM Notes & RTQ Responses
          </Box>
          <Box ref={sectionRefs.RTQResponses}>
            {selectedContactRTQ && <PMNotesCard rtq={selectedContactRTQ} hasTabs={false} />}
          </Box>
          <Box marginTop="largest"></Box>
          <Box as="div" ref={sectionRefs.Definitions}>
            <Definitions />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ComplianceTasksCard;
