import { FC } from 'react';
import { Box, styled, TextAreaInput, themeGet } from '@qwealth/qcore';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectComplianceContact,
  selectComplianceSelectedChecklistNotes,
} from 'data/selectors/compliance';
import { setComplianceSelectedChecklistNotes } from 'data/actions/compliance';
import { ComplianceNote, ComplianceNotesMap } from 'data/models/Compliance';

const StyledSectionNotesContainer = styled(TextAreaInput)`
  width: 100%;
  min-height: 200px;
  background-color: ${themeGet('colors.white')};
  padding: ${themeGet('space.default')};
  border-radius: ${themeGet('radii.default')};
  color: ${themeGet('colors.dark')};
  font-size: ${themeGet('fontSizes.default')};
  font-weight: ${themeGet('fontWeights.normal')};
`;

interface ChecklistSectionNotesProps {
  sectionId: number;
  sectionTitle: string;
  note: string;
}

const ChecklistSectionNotes: FC<ChecklistSectionNotesProps> = ({
  note,
  sectionTitle,
  sectionId,
}) => {
  const checklistNotes = useSelector(selectComplianceSelectedChecklistNotes);
  const selectedContact = useSelector(selectComplianceContact);
  const dispatch = useDispatch();

  const makeNewNote = (noteContent: string): ComplianceNote => {
    return {
      content: noteContent,
      note_type: 'compliance',
      owners: [selectedContact?.QID as string],
      title: sectionTitle,
      item_id: sectionId,
    };
  };

  const updateNotes = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const noteContent = event.currentTarget.value;
    let updatedNotes: ComplianceNotesMap = {};

    if (checklistNotes) {
      updatedNotes = JSON.parse(JSON.stringify(checklistNotes));
    }

    if (updatedNotes[sectionId]) {
      updatedNotes[sectionId].content = noteContent;
    } else {
      const newSectionNote = makeNewNote(noteContent);
      updatedNotes[sectionId] = newSectionNote;
    }

    dispatch(setComplianceSelectedChecklistNotes(updatedNotes));
  };

  return (
    <Box fontWeight="bold">
      Notes:
      <StyledSectionNotesContainer onBlur={e => updateNotes(e)} defaultValue={note} />
    </Box>
  );
};

export default ChecklistSectionNotes;
