import { FC, useState } from 'react';

import { Box, Loader } from '@qwealth/qcore';

import { ComplianceHousehold } from 'data/models/Compliance';
import { useAppSelector } from 'data/store';
import { selectComplianceHouseholds } from 'data/selectors/compliance';
import ReviewItem from './ReviewItem';

interface ReviewCardProps {
  setHouseholdSelected: (household: ComplianceHousehold | undefined) => void;
  isLoading: boolean;
}

const ReviewsCard: FC<ReviewCardProps> = ({ setHouseholdSelected, isLoading }): JSX.Element => {
  const [openId, setOpenId] = useState<number | undefined>();
  const households = useAppSelector(selectComplianceHouseholds);

  const clickOpen = (id: number, household: ComplianceHousehold | undefined) => {
    openId === id ? setOpenId(undefined) : setOpenId(id);
    setHouseholdSelected(household);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="50%"
      height="100vh"
      backgroundColor="white"
      padding="large"
      boxShadow="layout"
    >
      <Box
        fontSize="appTitle"
        fontWeight="bold"
        marginBottom="large"
        display="flex"
        flexDirection="column"
      >
        Reviews
        <Box height="1px" backgroundColor="gray.0" />
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <Box width="100%" overflowY="scroll" display="flex" flexDirection="column" gap="default">
          {households.length > 0 ? (
            households.map((household, index) => {
              return (
                <Box key={'review' + index}>
                  <ReviewItem
                    id={index}
                    isOpen={index === openId}
                    household={household}
                    onClick={clickOpen}
                    type="household"
                  />
                </Box>
              );
            })
          ) : (
            <Box> No tickets available </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ReviewsCard;
