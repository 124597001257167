import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@qwealth/qcore';

import {
  fetchComplianceHouseholds,
  getComplianceChecklistsForHousehold,
  setSelectedComplianceContact,
  setSelectedComplianceHousehold,
} from 'data/actions/compliance';
import { ComplianceContact, ComplianceHousehold, ShortCode } from 'data/models/Compliance';
import {
  selectComplianceContact,
  selectComplianceHousehold,
  selectHouseholds,
} from 'data/selectors/compliance';
import { useAppSelector } from 'data/store';
import ReviewsCard from './Reviews/ReviewsCard';
import HouseholdCard from './Reviews/HouseholdCard';
import ComplianceChecklistCard from './Checklist/ComplianceChecklistCard';
import ComplianceHeader from './Header/ComplianceHeader';
import ComplianceTasksCard from './Tasks/ComplianceTasksCard';
import { selectUserIsAdmin, selectUserIsCompliance } from '@qwealth/qdata';
import { errorHandler } from 'services/axiosService';

const selectDocumentShortCodes = [
  'welcome_letter',
  'referral_disclosure',
  'all_ima_agreement_sigs',
  'all_ima_fee_sigs',
  'partner_office',
  'household_set_up',
  'rep_code_match',
  'ips_check',
  'ips_completed',
  'ips_language',
  'investment_restriction',
  'ips_signed',
  'advising_rep_name',
  'all_fields_populated',
];

const CompliancePage: FC = (): JSX.Element => {
  const isUserAdmin = useSelector(selectUserIsAdmin);
  const isUserCompliance = useSelector(selectUserIsCompliance);
  const canViewCompliance = isUserAdmin || isUserCompliance;
  const households = useAppSelector(selectHouseholds);
  const selectedHousehold = useAppSelector<ComplianceHousehold | undefined>(
    selectComplianceHousehold,
  );
  const [householdQID, setHouseholdQID] = useState<string | undefined>(useParams().household);
  const selectedContact = useAppSelector(selectComplianceContact);
  const [selectedContactQID, setSelectedContactQID] = useState<string | undefined>(
    useParams().client,
  );
  const [taskToDisplay, setTaskToDisplay] = useState<ShortCode>('SelectDocument');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (canViewCompliance) {
      fetchAllTickets();
    } else {
      handleUnauthorizedAccess();
    }
  }, [canViewCompliance]);

  useEffect(() => {
    if (selectedContact === undefined) {
      clickContact(undefined);
    }
  }, [selectedContact]);

  useEffect(() => {
    const paramsHousehold = getComplianceHousehold();
    paramsHousehold && dispatch(setSelectedComplianceHousehold(paramsHousehold));

    if (selectedContactQID && paramsHousehold) {
      const paramsContact = getComplianceContact(paramsHousehold);
      paramsContact && clickContact(paramsContact);
    } else {
      paramsHousehold && clickHousehold(paramsHousehold);
    }
  }, [households]);

  const handleUnauthorizedAccess = () => {
    errorHandler(
      dispatch,
      'Unauthorized to view compliance page',
    )(new Error('Unauthorized to view compliance page'));
    navigate('/');
  };

  const getComplianceHousehold = (): ComplianceHousehold | undefined => {
    const household = households.find(household => household.QID === householdQID);
    household && dispatch(getComplianceChecklistsForHousehold(household));
    return household;
  };

  const getComplianceContact = (household: ComplianceHousehold | undefined) => {
    return household?.members.find(member => member.QID === selectedContactQID);
  };

  const clickHousehold = async (household: ComplianceHousehold | undefined) => {
    if (household) {
      await dispatch(setSelectedComplianceHousehold(household));
      await dispatch(getComplianceChecklistsForHousehold(household));
    }
    setSelectedContact(undefined);
    setHouseholdQID(household?.QID);
    navigate(`/compliance/${household?.QID}`);
  };

  const setSelectedContact = async (contact: ComplianceContact | undefined) => {
    await dispatch(setSelectedComplianceContact(contact));
  };

  const clickContact = async (contact: ComplianceContact | undefined) => {
    setSelectedContact(contact);

    if (contact) {
      await dispatch(setSelectedComplianceContact(contact));
      changeTaskItem('SelectDocument');
    }
    contact
      ? navigate(`/compliance/${householdQID}/${contact.QID}`)
      : navigate(`/compliance/${householdQID}`);
  };

  const clickReviews = () => {
    setSelectedContact(undefined);
    navigate('/compliance');
  };

  const changeTaskItem = (changeTo: ShortCode) => {
    if (changeTo === 'PDFViewer' || changeTo === 'SelectDocument' || changeTo === 'QVault') {
      setTaskToDisplay(changeTo);
    } else if (selectDocumentShortCodes.includes(changeTo)) {
      setTaskToDisplay('SelectDocument');
    } else {
      setTaskToDisplay('All Contact Info');
    }
  };

  const fetchAllTickets = async () => {
    await dispatch(fetchComplianceHouseholds());
    setIsLoading(false);
  };

  if (canViewCompliance) {
    return (
      <Box display="flex" flexDirection="column">
        <ComplianceHeader
          selectedHousehold={selectedHousehold}
          selectedClient={selectedContact?.name}
          clickHousehold={clickHousehold}
          clickReviews={clickReviews}
        />

        <Box display="flex" flexDirection="row" gap="large">
          {!selectedContact ? (
            <>
              <ReviewsCard setHouseholdSelected={clickHousehold} isLoading={isLoading} />
              <HouseholdCard
                household={selectedHousehold}
                selectContact={clickContact}
                isLoading={isLoading}
              />
            </>
          ) : (
            <>
              <ComplianceChecklistCard clickBack={() => clickContact(undefined)} />
              <ComplianceTasksCard taskToDisplay={taskToDisplay} changeTo={changeTaskItem} />
            </>
          )}
        </Box>
      </Box>
    );
  } else {
    return <Box margin="auto">User is not authorized to view this resource.</Box>;
  }
};

export default CompliancePage;
