import React, { FC, useEffect, useState } from 'react';

import { Box, Loader } from '@qwealth/qcore';
import { getPathContents, getRootPath, S3Item } from '@qwealth/qvault';

import { ShortCode } from 'data/models/Compliance';
import { useAppSelector } from 'data/store';
import { selectComplianceContact, selectComplianceHousehold } from 'data/selectors/compliance';
import BackButton from '../Common/BackButton';
import PdfDocumentButton from './PdfDocumentButton';

type foldersWithPdfs = {
  location: string;
  pdfs: S3Item[];
  pathToFolderLength: number;
};

type fileLocation = {
  locationLabel: string;
  folderPath: string;
  isAdvisorView: boolean;
};

const fileLocations: fileLocation[] = [
  {
    locationLabel: 'Advisor View/Onboarding/Docusign',
    folderPath: '/Onboarding/Docusign',
    isAdvisorView: true,
  },
  {
    locationLabel: 'Client View/Onboarding/IPS',
    folderPath: '/Onboarding/IPS',
    isAdvisorView: false,
  },
  {
    locationLabel: 'Client View/Onboarding/IMA',
    folderPath: '/Onboarding/IMA',
    isAdvisorView: false,
  },
];

interface DocumentSelectionProps {
  changeTo: (changeTo: ShortCode) => void;
  setPdfToDisplay: React.Dispatch<React.SetStateAction<S3Item | undefined>>;
}

const DocumentSelection: FC<DocumentSelectionProps> = ({
  changeTo,
  setPdfToDisplay,
}): JSX.Element => {
  const [foldersWithPDFs, setFoldersWithPDFs] = useState<foldersWithPdfs[]>([]);
  const selectedContact = useAppSelector(selectComplianceContact);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const riskLevel = selectedContact?.rtqLevel;
  const [areDocuments, setAreDocuments] = useState<boolean>(false);

  useEffect(() => {
    getFiles();
  }, []);

  const getFolderWithPdfs = async (
    qid: string,
    locationName: string,
    folderPath: string,
    isAdvisorView: boolean,
  ) => {
    const rootPath = getRootPath(qid, isAdvisorView);
    const pathToFiles = rootPath + folderPath;
    const filePathContents = (await getPathContents(pathToFiles, true)).filter(path => {
      return path.key && path.key?.length > 3 && path.key.slice(-3) === 'pdf'; // Only return the pdfs
    });
    const pathToFolderLength = pathToFiles.length + 1; // +1 for the '/' character

    if (filePathContents.length > 0) {
      setAreDocuments(true);
    }

    return {
      location: locationName,
      pdfs: filePathContents,
      pathToFolderLength: pathToFolderLength,
    } as foldersWithPdfs;
  };

  const getFiles = async () => {
    const allFolders: foldersWithPdfs[] = [];

    const qid = riskLevel === 'Household' ? selectedHousehold?.QID : selectedContact?.QID;

    await fileLocations.map(async fileLocation => {
      allFolders.push(
        await getFolderWithPdfs(
          qid as string,
          fileLocation.locationLabel,
          fileLocation.folderPath,
          fileLocation.isAdvisorView,
        ),
      );
    });

    setFoldersWithPDFs(allFolders);
    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box fontSize="sectionTitle" fontWeight="bold">
        Related Documents
      </Box>

      {isLoading && <Loader />}

      {!isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          marginTop="large"
          marginBottom="largest"
          gap="large"
          marginLeft="large"
        >
          {!areDocuments && <Box>No Documents found.</Box>}

          {areDocuments &&
            foldersWithPDFs.map((folder, index) => {
              return (
                <Box key={folder.location + index}>
                  {folder.pdfs.length > 0 && (
                    <Box fontSize="large" fontWeight="bold" marginBottom="default">
                      {folder.location}
                    </Box>
                  )}

                  {folder.pdfs.map((pdf, index) => {
                    return (
                      <Box key={(pdf.key ? pdf.key : 'NoPdfKey') + index}>
                        <PdfDocumentButton
                          setPdfToDisplay={setPdfToDisplay}
                          changeTo={changeTo}
                          pathToFolderLength={folder.pathToFolderLength}
                          pdf={pdf}
                        />
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
      )}

      <BackButton
        clickBack={() => changeTo('QVault')}
        hasIcon={false}
        btnText={'Go To QVault'}
        textSize="default"
      />
    </Box>
  );
};

export default DocumentSelection;
