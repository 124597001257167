import { FC } from 'react';
import { Box, Loader } from '@qwealth/qcore';
import ReviewItem from './ReviewItem';
import HouseholdChecklistButton from './HouseholdChecklistButton';
import { ComplianceContact, ComplianceHousehold } from 'data/models/Compliance';
import { useAppSelector } from 'data/store';
import { selectComplianceCheckslists } from 'data/selectors/compliance';

interface householdCardProps {
  household: ComplianceHousehold | undefined;
  isLoading: boolean;
  selectContact: (contact: ComplianceContact | undefined) => void;
}

const HouseholdCard: FC<householdCardProps> = ({
  household,
  selectContact,
  isLoading,
}): JSX.Element => {
  const checklists = useAppSelector(selectComplianceCheckslists);

  const getContactChecklistStatus = (contactQid: string) => {
    const contactChecklist = checklists.find(checklist => {
      return checklist.contactQID === contactQid;
    });

    return contactChecklist?.checklistStatus === 'yes' ? true : undefined;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="50%"
      height="100vh"
      backgroundColor="white"
      padding="large"
      boxShadow="layout"
    >
      <Box
        fontSize="appTitle"
        fontWeight="bold"
        marginBottom="large"
        display="flex"
        flexDirection="column"
      >
        Household
        <Box height="1px" backgroundColor="gray.0" />
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        household && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap="large"
            backgroundColor="gray.4"
          >
            <ReviewItem
              id={0}
              household={household}
              isOpen={false}
              onClick={(id: number) => {}}
              type="household"
            />

            <Box
              display="flex"
              flexDirection="column"
              paddingLeft="large"
              paddingRight="large"
              paddingBottom="large"
              gap="large"
            >
              <Box fontSize="large" fontWeight="bold">
                Clients
              </Box>

              {household.members.map((contact, index) => {
                return (
                  <Box key={contact.QID + index}>
                    <HouseholdChecklistButton
                      name={contact.name}
                      checklistStatus={contact.complianceChecklistStatus}
                      ipsAcceptanceDate={contact.ipsAcceptanceDate}
                      onClick={() => selectContact(contact)}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default HouseholdCard;
