import React, { ReactElement, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { Box, Loader } from '@qwealth/qcore';
import { CitizenshipAndResidencyConfirmation } from '@qwealth/kyc';
import { useAppSelector } from 'data/store';
import EditModal from './EditModal';
import { EditButton } from '../../Common';

interface Props {
  contactQID: string;
  isEditDisabled?: boolean;
}

const CitizenshipAndResidency = ({ contactQID, isEditDisabled }: Props): ReactElement => {
  const demographicInfo = useAppSelector(state => state.contacts.contact);
  const loading = useAppSelector(state => state.contacts.loading);

  const [showModal, setShowModal] = useState<boolean>(false);

  if (loading.contact) {
    return <Loader />;
  }

  return (
    <Box overflow="auto" style={{ position: 'relative' }}>
      <EditButton
        disabled={isEditDisabled === true}
        bg="transparent"
        color="primaryText"
        variant="icon"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <BsPencil />
      </EditButton>
      <CitizenshipAndResidencyConfirmation demographicInfo={demographicInfo} />
      <EditModal
        demographicInfo={demographicInfo}
        contactQID={contactQID}
        shouldShowModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </Box>
  );
};

export default CitizenshipAndResidency;
