import { FC, ReactElement, useEffect, useState } from 'react';

import { Box } from '@qwealth/qcore';
import { getFile, S3Item } from '@qwealth/qvault';

interface PDFViewProps {
  defaultPdf: S3Item | undefined;
}

const PDFViewer: FC<PDFViewProps> = ({ defaultPdf }): ReactElement => {
  const [pdfUrl, setPdfUrl] = useState<string>();

  const getPDF = async () => {
    if (defaultPdf) {
      const myPDFBlob = await getFile(defaultPdf.key as string);
      const pdfBlob = myPDFBlob?.slice(0, myPDFBlob?.size, 'application/pdf');
      const myUrl = await URL.createObjectURL(pdfBlob as Blob);
      setPdfUrl(myUrl);
    }
  };

  useEffect(() => {
    getPDF();
  }, []);

  return (
    <Box minWidth="100%" minHeight="100%">
      <Box minWidth="100%" height="100%" maxHeight="1100px" overflowY="scroll">
        <iframe src={pdfUrl} width="100%" height="1100px" style={{ border: 'none' }} />
      </Box>
    </Box>
  );
};

export default PDFViewer;
