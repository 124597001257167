import React, { ReactElement, useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SilentRequest } from '@azure/msal-browser';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import {
  AppLayout,
  Box,
  Button,
  Loader,
  getAccessAndIdToken,
  useAcquireToken,
} from '@qwealth/qcore';
import { QContext, selectUser } from '@qwealth/qdata';

import { WebSocketService } from './services/WebSocketService';
import { useAppDispatch } from './data/store';
import { loadUserInfo } from 'data/actions/auth';
import { loadUser } from 'data/actions/microsoft/user';
import LoginPage from 'components/LoginPage';
import { configureAxios } from './data/actions/axios';
import { USER_HOME_KEY } from './data/refData/localCache';
import './App.css';
import Layout from './components/Layout';
import CompliancePage from 'components/Compliance/CompliancePage';

const AdminPage = lazy(() => import('components/Admin'));
const AlertPage = lazy(() => import('components/AlertList'));
const CreateContactPage = lazy(() => import('components/Contacts/AddContactCard'));
const ContactDetailPage = lazy(() => import('components/Contacts/ContactDetails'));
const FeePage = lazy(() => import('./components/common/Fees/index'));
const HouseholdDetailPage = lazy(() => import('components/Households/HouseholdDetails'));
const KilledPage = lazy(() => import('components/KilledByQw'));
const LegalEntityPage = lazy(() => import('components/LegalEntities'));
const MyList = lazy(() => import('./components/MyList'));
const ProfilePage = lazy(() => import('components/UserProfile'));
const ProspectDetailPage = lazy(
  () => import('components/Households/Prospect/ProspectHouseholdDetails'),
);
const QBoardPage = lazy(() => import('components/QBoard'));
const QNalyticsPage = lazy(() => import('components/QNalytics'));
const ReportPage = lazy(() => import('components/Reports'));
const DashboardPage = lazy(() => import('components/Dashboard'));

const { REACT_APP_QWEALTH_S3_INTEGRATION_USER, REACT_APP_QWEALTH_S3_INTEGRATION_PASSWORD } =
  process.env;

const cognitoUser = REACT_APP_QWEALTH_S3_INTEGRATION_USER || '';
const cognitoSecret = REACT_APP_QWEALTH_S3_INTEGRATION_PASSWORD || '';

const userHome = localStorage.getItem(USER_HOME_KEY);
const { pathname } = window.location;

const KycContainer = lazy(() => import('./components/KycContainer'));
const RTSurvey = lazy(() => import('./components/RTSurvey'));
const EntitySurvey = lazy(() => import('./components/EntityRTSurvey'));
const QBiasSurvey = lazy(() => import('./components/QBiasSurvey'));

const amplifyLogin = async () => {
  if (!cognitoUser || !cognitoSecret) {
    console.warn('Missing configurations for S3 Integration username/password');
  }
  await Auth.signIn(cognitoUser, cognitoSecret).catch(error => {
    console.warn('Cognito manual connection failed');
    console.error(error);
  });
};

const errorHandlerWithRetry = err => {
  console.error('failed to refresh cognito token', err);
  amplifyLogin()
    .then(() => console.log('Cognito manually connected!'))
    .catch(e => {
      console.error('errorHandlerWithRetry failed', e);
    });
};

function App(): ReactElement {
  const { idToken, account, isInProgress } = useAcquireToken();
  const user = useSelector(selectUser);

  const [isAxiosInitialized, setAxiosInitialized] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const refreshToken = useCallback(() => {
    return getAccessAndIdToken({ forceRefresh: true } as SilentRequest)
      .then(auth => {
        if (auth) {
          if (auth) {
            QContext.setIdToken(auth.idToken);
            if (user) {
              WebSocketService.connect(user.email, auth.idToken);
            }
          }
        }
      })
      .then(() => Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]))
      .then(([currentUser, currentSession]) =>
        currentUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          if (err) {
            errorHandlerWithRetry(err);
          } else {
            console.log('Cognito connected and refreshed!', session);
          }
        }),
      )
      .catch(errorHandlerWithRetry);
  }, []);

  useEffect(() => {
    amplifyLogin()
      .then(() => console.log('Cognito automatically connected!'))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (userHome && pathname === '/') {
      navigate(userHome);
    }
  }, []);

  useEffect(() => {
    if (idToken && account) {
      Sentry.setUser({ email: account.username });

      QContext.setIdToken(idToken);
      dispatch(configureAxios());
      dispatch(loadUserInfo(account.username));
      dispatch(loadUser());

      setAxiosInitialized(true);

      // refresh the token every 10 minutes
      setInterval(refreshToken, moment.duration(10, 'minutes').asMilliseconds());
    }
  }, [idToken, account, dispatch]);

  if (!account) {
    return <LoginPage />;
  }

  if (isInProgress || !isAxiosInitialized || !user) {
    return (
      <AppLayout alignItems="center">
        <Box display="flex" flexDirection="column" margin="auto">
          <Loader />
          <Button
            onClick={() => {
              refreshToken().then(() => {
                history.go(0);
              });
            }}
          >
            Login
          </Button>
        </Box>
      </AppLayout>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/kyc-onboarding/*" element={<KycContainer />} />
            <Route path="/rt-survey" element={<RTSurvey />} />
            <Route path="/entity-rtq" element={<EntitySurvey />} />
            <Route path="/qbias-survey" element={<QBiasSurvey />} />
            <Route element={<Layout />}>
              <Route index element={<QBoardPage />} />
              <Route path="admin/*" element={<AdminPage />} />
              <Route path="alerts" element={<AlertPage />} />
              <Route path="contacts/create" element={<CreateContactPage />} />
              <Route path="contacts/:contactQID" element={<ContactDetailPage />} />
              <Route path="fees/*" element={<FeePage />} />
              <Route path="household" element={<HouseholdDetailPage />} />
              <Route path="killed" element={<KilledPage />} />
              <Route path="legalEntities/:legalEntityQID" element={<LegalEntityPage />} />
              <Route path="my-list" element={<MyList />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route
                path="prospect-households/:prospectHouseholdQID"
                element={<ProspectDetailPage />}
              />
              <Route path="qboard" element={<QBoardPage />} />
              <Route path="qnalytics" element={<QNalyticsPage />} />
              <Route path="report/:id" element={<ReportPage />} />
              <Route path="dashboards/:id" element={<DashboardPage />} />
              <Route
                path="compliance/:household?/:client?/:section?"
                element={<CompliancePage />}
              />
              <Route path="*" element={<h3>Invalid Route: please check the URL...</h3>} />
            </Route>
          </Routes>
        </Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
