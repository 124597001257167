import { FC } from 'react';

import { Box } from '@qwealth/qcore';

import { useAppSelector } from 'data/store';
import { selectComplianceContact } from 'data/selectors/compliance';
import { ComplianceContact, ShortCode } from 'data/models/Compliance';
import ReviewItem from '../Reviews/ReviewItem';
import Checklist from './Checklist';
import BackButton from '../Common/BackButton';

interface ComplianceChecklistCardProps {
  clickBack: () => void;
}

const ComlianceChecklistCard: FC<ComplianceChecklistCardProps> = ({ clickBack }): JSX.Element => {
  const contact = useAppSelector(selectComplianceContact);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="50%"
      maxHeight="100vh"
      backgroundColor="white"
      padding="large"
      boxShadow="layout"
      overflowY="hidden"
    >
      <Box
        fontSize="appTitle"
        fontWeight="bold"
        marginBottom="large"
        display="flex"
        flexDirection="column"
      >
        <BackButton clickBack={clickBack} hasIcon={true} btnText="Back" />
        <Box height="1px" backgroundColor="gray.0" />
      </Box>
      <Box backgroundColor="gray.4">
        <ReviewItem
          contact={contact as ComplianceContact}
          id={0}
          isOpen={false}
          onClick={(id: number) => {}}
          type={contact?.rtqLevel === 'Corporate' ? 'corp/trust' : 'client'}
        />
        <Box overflowY="auto">
          <Checklist />
        </Box>
      </Box>
    </Box>
  );
};

export default ComlianceChecklistCard;
