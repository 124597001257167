import React, { useState } from 'react';
import { Box } from '@qwealth/qcore';
import { ComplianceQuestion, ShortCode, YesNoNaOption } from 'data/models/Compliance';
import SectionQuestion from './SectionQuestion';

interface SectionQuestionWithSubquestionsProps {
  question: ComplianceQuestion;
  isCompliant: boolean | undefined;
  isAdvisorView?: boolean;
  incrementNonCompliance: (change: 1 | -1) => void;
  updateQuestionAnswer: (questionId: string, option: string) => void;
}

const SectionQuestionWithSubquestions: React.FC<SectionQuestionWithSubquestionsProps> = ({
  question,
  isCompliant,
  incrementNonCompliance,
  updateQuestionAnswer,
}) => {
  const numSubQuestions = question?.children?.length;
  const [numcompliant, setNumCompliant] = useState<number>(0);
  const [numNoncompliant, setNumNotCompliant] = useState<number>(0);

  const clickOption = (
    prevOption: YesNoNaOption | undefined,
    option: YesNoNaOption | undefined,
  ) => {
    const adjustCounts = (type: YesNoNaOption | undefined, delta: number) => {
      if (type === 'yes' || type === 'n/a') {
        setNumCompliant(numcompliant + delta);
      } else if (type === 'no') {
        setNumNotCompliant(numNoncompliant + delta);
      }
    };

    // Decrease counts for prevOption
    adjustCounts(prevOption, -1);
    // Increase counts for option
    adjustCounts(option, 1);
  };

  const evaluateComplianceForSectionQuestion = () => {
    if (numNoncompliant > 0) return false;
    if (isCompliant && numNoncompliant === 0) return true;
    if (numcompliant === numSubQuestions) return true;
    return undefined;
  };

  return (
    <Box>
      <SectionQuestion
        isAdvisorView={true}
        question={question}
        isCompliant={evaluateComplianceForSectionQuestion()}
        incrementNonCompliance={incrementNonCompliance}
        updateQuestionAnswer={updateQuestionAnswer}
      />

      {question?.children &&
        question.children.map((subquestion, index) => {
          return (
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="row" marginTop="small" marginLeft="default">
                <img height="24px" src="/subquestionIcon1.svg" />
                <img height="24px" src="/subquestionIcon2.svg" />
              </Box>

              <Box
                key={question.text + index}
                marginTop="default"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <SectionQuestion
                  question={subquestion}
                  isCompliant={isCompliant}
                  updateParentQuestion={clickOption}
                  incrementNonCompliance={incrementNonCompliance}
                  updateQuestionAnswer={updateQuestionAnswer}
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default SectionQuestionWithSubquestions;
