import React, { ReactElement } from 'react';
import { NavLink } from 'react-bootstrap';
import { Box, styled, themeGet } from '@qwealth/qcore';
import InfoTag from '../InfoTag';
import { SectionHeaderDetailColumnContent } from '../SectionHeaderDetailColumn';

export const StyledSectionHeaderLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  color: ${themeGet('colors.dark')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.thin')};
  align-items: center;
  width:s fit-content;
  gap: default;

  &:hover {
    color: ${themeGet('colors.primary')};
  }
`;

interface ContentLinkProps {
  contents: SectionHeaderDetailColumnContent[];
  icon: JSX.Element;
  hasKYCTimer?: boolean;
}

const ContentLinks: React.FC<ContentLinkProps> = ({ contents, icon, hasKYCTimer }): ReactElement => (
  <>
    {contents.map((content, index) => (
      content ? (
        <React.Fragment key={`${content.label}-${index}`}>
          <StyledSectionHeaderLink href={content.link}>
            {icon}
            <Box as='span' marginRight='small' marginLeft='small'>{content.label}</Box>
            {content.infoTag}
          </StyledSectionHeaderLink>
          {hasKYCTimer && content.kycDaysRemaining !== undefined && !Number.isNaN(content.kycDaysRemaining) && (
            <InfoTag 
              tagTitle={content.kycDaysRemaining <= 0 ? 'KYC Warning' : 'KYC Reminder'} 
              numberToDisplay={content.kycDaysRemaining}
            />
          )}
        </React.Fragment>
      ) : (
        <Box as='span' key={`na-${index}`}>N/A</Box>
      )
    ))}
  </>
);

export default ContentLinks;