import { FC } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Box, styled, themeGet } from '@qwealth/qcore';

interface StyledBackButtonProps {
  textSize: string;
}

const StyledBackButton = styled.button<StyledBackButtonProps>`
  background: none;
  border: none;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: ${themeGet('space.small')};
  align-items: center;
  font-size: ${props => props.textSize};
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.dark')};

  &:hover {
    color: ${themeGet('colors.primaryLight.1')};
  }
`;

const ArrowContainer = styled(Box)`
  &:hover {
    transform: translateX(-5px);
    transition: transform 0.3s ease;
  }
`;

interface BackButtonProps {
  clickBack: () => void;
  hasIcon?: boolean;
  btnText: string;
  textSize?: string;
}

const BackButton: FC<BackButtonProps> = ({
  clickBack,
  hasIcon,
  btnText,
  textSize,
}): JSX.Element => {
  return (
    <StyledBackButton
      onClick={clickBack}
      textSize={textSize ? themeGet('fontSizes.sectionTitle') : themeGet('fontSizes.appTitle')}
    >
      {hasIcon && (
        <ArrowContainer>
          <BsArrowLeft strokeWidth={1.5} />
        </ArrowContainer>
      )}
      {btnText}
    </StyledBackButton>
  );
};

export default BackButton;
