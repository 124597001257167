import { FC } from 'react';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { BsCheck, BsX } from 'react-icons/bs';

interface StyledComplianceCheckboxProps {
  checkboxColor: string;
  isSmall?: boolean;
}

const StyledComplianceCheckbox = styled(Box)<StyledComplianceCheckboxProps>`
  min-width: ${props => (props.isSmall ? '18px' : '24px')};
  height: ${props => (props.isSmall ? '18px' : '24px')};
  background-color: ${props => props.checkboxColor};
  border-radius: ${themeGet('radii.default')};
  border-color: ${themeGet('colors.dark')};
  border: 2px;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.isSmall ? '2px' : '0px')};
`;

interface CompletionCheckboxProps {
  isCompliant: boolean | undefined;
  isSmall?: boolean;
}

const CompletionCheckbox: FC<CompletionCheckboxProps> = ({ isCompliant, isSmall }): JSX.Element => {
  const getColor = () => {
    if (isCompliant) {
      return themeGet('colors.green');
    } else if (isCompliant === undefined) {
      return themeGet('colors.white');
    } else {
      return themeGet('colors.red');
    }
  };

  return (
    <StyledComplianceCheckbox checkboxColor={getColor()} isSmall={isSmall}>
      <Box
        alignItems="center"
        justifyContent="center"
        minHeight="100%"
        minWidth="100%"
        display="flex"
      >
        {isCompliant === true && (
          <BsCheck size={isSmall ? 14 : 20} style={{ strokeWidth: 0.5, color: 'white' }} />
        )}
        {isCompliant === false && (
          <BsX size={isSmall ? 14 : 20} style={{ strokeWidth: 1, color: 'white' }} />
        )}
      </Box>
    </StyledComplianceCheckbox>
  );
};

export default CompletionCheckbox;
