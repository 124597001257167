import { createAction, Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  ChecklistSubmission,
  ComplianceChecklistWithNotes,
  ComplianceContact,
  ComplianceHousehold,
  ComplianceNotesMap,
  ComplianceQuestion,
  ContactChecklist,
  HouseholdChecklist,
} from 'data/models/Compliance';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { responseEntityMapper } from '@qwealth/qcore';

export const UPDATE_COMPLIANCE_CLIENT = 'UPDATE_COMPLIANCE_CLIENT';
export const GET_COMPLIANCE_HOUSEHOLDS = 'GET_COMPLIANCE_HOUSEHOLDS';
export const GET_COMPLIANCE_CONTACT_CHECKLIST = 'GET_COMPLIANCE_CONTACT_CHECKLIST';
export const SET_COMPLIANCE_SELECTED_HOUSEHOLD = 'SET_SELECTED_COMPLIANCE_HOUSEHOLD';
export const SET_COMPLIANCE_SELECTED_CONTACT = 'SET_SELECTED_COMPLIANCE_CONTACT';
export const SET_COMPLIANCE_CONTACT_CHECKLISTS = 'SET_COMPLIANCE_CONTACT_CHECKLISTS';
export const SET_COMPLIANCE_SELECTED_CHECKLIST = 'SET_COMPLIANCE_SELECTED_CHECKLIST';
export const SET_COMPLIANCE_SELECTED_CHECKLIST_NOTES = 'SET_COMPLIANCE_SELECTED_CHECKLIST_NOTES';

export const updateComplianceClient = createAction<HouseholdChecklist>(UPDATE_COMPLIANCE_CLIENT);
export const getComplianceHouseholds =
  createAction<ComplianceHousehold[]>(GET_COMPLIANCE_HOUSEHOLDS);

export const setComplianceSelectedHousehold = createAction<ComplianceHousehold>(
  SET_COMPLIANCE_SELECTED_HOUSEHOLD,
);

export const setComplianceSelectedContact = createAction<ComplianceContact | undefined>(
  SET_COMPLIANCE_SELECTED_CONTACT,
);

export const setComplianceContactChecklists = createAction<ContactChecklist[]>(
  SET_COMPLIANCE_CONTACT_CHECKLISTS,
);

export const setComplianceSelectedChecklist = createAction<ComplianceQuestion[]>(
  SET_COMPLIANCE_SELECTED_CHECKLIST,
);

export const setComplianceSelectedChecklistNotes = createAction<ComplianceNotesMap>(
  SET_COMPLIANCE_SELECTED_CHECKLIST_NOTES,
);

export const fetchComplianceHouseholds = () => {
  return async (dispatch: Dispatch): Promise<ComplianceHousehold[]> => {
    try {
      const response = await axios.get(`${REACT_APP_QWEALTH_API}/complianceChecklist/households`);
      const complianceHouseholds: ComplianceHousehold[] = response.data.data;
      dispatch(getComplianceHouseholds(complianceHouseholds));
      return complianceHouseholds;
    } catch (e) {
      await errorHandler(dispatch, 'API Error: Failed Fetching compliance households')(e);
      return [];
    }
  };
};

export const getComplianceChecklistForContact = async (
  contactQID: string,
): Promise<ComplianceChecklistWithNotes | undefined> => {
  try {
    return await axios
      .get(`${REACT_APP_QWEALTH_API}/complianceChecklist/${contactQID}`)
      .then(response => {
        return responseEntityMapper<ComplianceChecklistWithNotes>(response);
      });
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getComplianceChecklistsForHousehold = (household: ComplianceHousehold) => {
  return async (dispatch: Dispatch) => {
    const checklists: ContactChecklist[] = [];

    for (const member of household.members) {
      try {
        const checklistWithNotes = await getComplianceChecklistForContact(member.QID);
        checklists.push({
          contactQID: member.QID,
          contactName: member.name,
          checklist: checklistWithNotes?.checklist as ComplianceQuestion[],
          checklistStatus: member.complianceChecklistStatus,
          notes: checklistWithNotes?.notes as ComplianceNotesMap,
        });
      } catch (e) {
        console.error(e);
      }
    }

    try {
      dispatch(setComplianceContactChecklists(checklists));
    } catch (e) {
      console.error(e);
    }
    return checklists;
  };
};

export const setSelectedComplianceHousehold = (household: ComplianceHousehold) => {
  return async (dispatch: Dispatch): Promise<ComplianceHousehold | undefined> => {
    try {
      dispatch(setComplianceSelectedHousehold(household));
    } catch (e) {
      await errorHandler(dispatch, 'Error: Failed setting compliance households')(e);
      return undefined;
    }
  };
};

export const setSelectedComplianceContact = (contact: ComplianceContact | undefined) => {
  return async (dispatch: Dispatch): Promise<ComplianceHousehold | undefined> => {
    try {
      dispatch(setComplianceSelectedContact(contact));
    } catch (e) {
      await errorHandler(dispatch, 'Error: Failed setting compliance contact')(e);
      return undefined;
    }
  };
};

export const submitComplianceChecklist = (
  qid: string,
  checklistSubmission: ChecklistSubmission,
) => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post(`${REACT_APP_QWEALTH_API}/complianceChecklist/${qid}`, checklistSubmission);
    } catch (error) {
      errorHandler(dispatch, 'Error submitting compliance checklist review')(error);
      return;
    }
  };
};
