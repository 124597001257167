import React from 'react';
import { BsCheck, BsX } from 'react-icons/bs';
import { styled, themeGet } from '@qwealth/qcore';
import { YesNoNaOption } from 'data/models/Compliance';

interface StyledComplianceButtonProps {
  hoverColor: string;
  selectedColor: string;
  isSelected: boolean;
}

const StyledComplianceButton = styled.button<StyledComplianceButtonProps>`
  padding: none;
  border-radius: ${themeGet('radii.round')};
  width: 24px;
  height: 24px;
  border: none;
  align-items: center;
  background-color: ${props =>
    props.isSelected ? props.selectedColor : themeGet('colors.gray.2')};
  display: flex;
  justify-content: center;
  color: ${themeGet('colors.white')};

  &:hover {
    background-color: ${props => props.hoverColor};
    color: ${themeGet('colors.white')};
  }
`;

interface ComplianceButtonProps {
  type: YesNoNaOption;
  selected: boolean;
  clickOption: () => void;
}

const ComplianceButton: React.FC<ComplianceButtonProps> = ({ type, selected, clickOption }) => {
  return (
    <>
      {type === 'yes' && (
        <StyledComplianceButton
          isSelected={selected}
          hoverColor={themeGet('colors.green')}
          selectedColor={themeGet('colors.green')}
          onClick={clickOption}
        >
          <BsCheck size={24} style={{ strokeWidth: '1.5' }} />
        </StyledComplianceButton>
      )}
      {type === 'no' && (
        <StyledComplianceButton
          isSelected={selected}
          hoverColor={themeGet('colors.red')}
          selectedColor={themeGet('colors.red')}
          onClick={clickOption}
        >
          <BsX size={24} style={{ strokeWidth: '1.5' }} />
        </StyledComplianceButton>
      )}
      {type === 'n/a' && (
        <StyledComplianceButton
          isSelected={selected}
          hoverColor={themeGet('colors.blue')}
          selectedColor={themeGet('colors.blue')}
          onClick={clickOption}
        >
          na
        </StyledComplianceButton>
      )}
    </>
  );
};

export default ComplianceButton;
