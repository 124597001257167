import { FC } from 'react';
import { BsBuilding, BsHouseFill, BsPersonFill } from 'react-icons/bs';
import moment from 'moment';

import { Box, styled, themeGet } from '@qwealth/qcore';

import { StyledSectionHeaderLink } from 'components/common/SectionHeader/HeaderLinks/ContentLinks';
import InfoTag from 'components/common/SectionHeader/InfoTag';
import { useAppSelector } from 'data/store';
import { selectComplianceHousehold } from 'data/selectors/compliance';
import {
  ComplianceContact,
  ComplianceHousehold,
  ComplianceChecklistStatus,
} from 'data/models/Compliance';

interface ReviewItemContainerProps {
  isOpen: boolean;
}

const ReviewItemContainer = styled(Box)<ReviewItemContainerProps>`
  width: 100%;
  height: fit-content;
  padding: ${themeGet('space.default')};
  border-radius: ${themeGet('radii.large')};
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.isOpen ? themeGet('colors.gray.3') : themeGet('colors.gray.4')};

  &:hover {
    background-color: ${props =>
      props.isOpen ? themeGet('colors.gray.2') : themeGet('colors.gray.3')(props)};
  }
`;

interface ReviewItemProps {
  id: number;
  isOpen: boolean;
  type: 'household' | 'corp/trust' | 'client';
  household?: ComplianceHousehold;
  contact?: ComplianceContact;
  onClick: (id: number, household: ComplianceHousehold | undefined) => void;
}

const ReviewItem: FC<ReviewItemProps> = ({
  isOpen,
  id,
  type,
  household,
  contact,
  onClick,
}): JSX.Element => {
  const selectedHousehold = useAppSelector(selectComplianceHousehold);

  const getIPSAcceptanceDate = () => {
    if (household?.ipsAcceptanceDate && household.ipsAcceptanceDate.length > 9) {
      return moment(household.ipsAcceptanceDate).format('YYYY-MM-DD');
    } else if (contact?.ipsAcceptanceDate) {
      return moment(contact.ipsAcceptanceDate).format('YYYY-MM-DD');
    }
    return 'No Sign Date';
  };

  const getPartnerName = (): string => {
    if (household) {
      return household.partnerName;
    } else if (contact) {
      return contact.partnerName;
    } else {
      return '';
    }
  };

  const ipsAcceptanceDate = getIPSAcceptanceDate();
  const partnerName = getPartnerName();

  return (
    <ReviewItemContainer isOpen={isOpen} onClick={() => onClick(id, household)}>
      <Box display="flex" flexDirection="row">
        <Box
          backgroundColor="blueTransparent"
          display="flex"
          width="fit-content"
          padding="default"
          borderRadius="large"
          maxHeight="62px"
          maxWidth="62px"
        >
          {type === 'household' && <BsHouseFill fill="#3772B8" size={40} />}
          {type === 'corp/trust' && <BsBuilding fill="#3772B8" size={40} />}
          {type === 'client' && <BsPersonFill fill="#3772B8" size={40} />}
        </Box>

        <Box
          marginLeft="large"
          marginRight="large"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            marginBottom="default"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="large"
              fontSize="sectionTitle"
              fontWeight="bold"
              alignItems="center"
            >
              {household && household.name}
              {contact && contact.name}
            </Box>
            {!household && (
              <Box
                display="flex"
                flexDirection="row"
                fontSize="default"
                gap="default"
                alignItems="center"
              >
                <Box fontWeight="bold">Status:</Box>
                <InfoTag
                  tagTitle={contact?.complianceChecklistStatus as ComplianceChecklistStatus}
                />
              </Box>
            )}
            {household && (
              <Box
                display="flex"
                flexDirection="row"
                fontSize="default"
                gap="default"
                alignItems="center"
              >
                <StyledSectionHeaderLink href={`/household?household=${selectedHousehold?.QID}`}>
                  <Box as="a" color="dark" fontWeight="bold" fontSize="default">
                    Go To Household Page
                  </Box>
                </StyledSectionHeaderLink>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {contact && (
              <Box display="flex" flexDirection="row" alignItems="center" gap="default">
                <Box fontWeight="bold">Household:</Box>
                <StyledSectionHeaderLink href={`/household?household=${selectedHousehold?.QID}`}>
                  <Box>
                    <BsHouseFill size={16} />
                  </Box>
                  <Box paddingTop="small" marginLeft="small">
                    {selectedHousehold && selectedHousehold.name}
                  </Box>
                </StyledSectionHeaderLink>
              </Box>
            )}

            {contact && (
              <Box display="flex" flexDirection="row" gap="default" alignItems="center">
                <Box fontWeight="bold">Risk Level:</Box>
                <InfoTag tagTitle={contact.rtqLevel} />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap="default">
              <Box fontWeight="bold">Partner:</Box>
              <Box> {partnerName} </Box>
            </Box>

            <Box display="flex" flexDirection="row" gap="default" alignItems="center">
              <Box fontWeight="bold">IPS Date:</Box>
              <Box> {ipsAcceptanceDate} </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ReviewItemContainer>
  );
};

export default ReviewItem;
