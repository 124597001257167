import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FaCog, FaSignOutAlt, FaQuestion } from 'react-icons/fa';
import { Box, Button, logout, styled } from '@qwealth/qcore';
import { Q_ISPRING_URL } from 'data/refData/constants';
import { getUserAvatar } from 'services/microsoft/graphService';
import SearchBar from './SearchBar';
import { Avatar, DefaultAvatar } from './Avatars';
import AlertBadge from './AlertBadge';

const url = window.URL || window.webkitURL;

const ProfileWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export default function (): ReactElement {
  const navigate = useNavigate();
  // @ts-ignore
  const user = useSelector(state => state.microsoft.user);

  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [source, setSource] = useState<string | null>(null);
  const [initials, setInitials] = useState<string>('N/A');

  useEffect(() => {
    if (!source) {
      getUserAvatar()
        .then(data => url.createObjectURL(data))
        .then(blobUrl => setSource(blobUrl))
        .catch(err => console.error(err));
    }
  }, [source]);

  useEffect(() => {
    if (user.givenName && user.surname) {
      setInitials(`${user.givenName.substring(0, 1)}${user.surname.substring(0, 1)}`);
    }
  }, [user]);

  const handleProfileClick = () => {
    setShowProfilePopup(false);
    navigate({ pathname: '/profile' });
  };

  const handleQWikiClick = () => {
    window.open(Q_ISPRING_URL, '_blank');
  };

  const logoutHandler = async () => {
    await Auth.signOut({ global: true });
    await logout();
  };

  const profilePopover = (
    <Popover id="profile">
      <Popover.Body>
        <Button variant="outline" mb="default" onClick={handleProfileClick}>
          <FaCog size="15px" />
          &nbsp;Settings
        </Button>
        <Button color="primary" onClick={logoutHandler}>
          <FaSignOutAlt size="15px" />
          &nbsp;Logout
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <Box display="flex" justifyContent="space-between" mb="large">
      <SearchBar />
      <Box display="flex" flexDirection="row" gap="largest">
        <ProfileWrapper>
          <Box>
            <AlertBadge />
          </Box>
        </ProfileWrapper>
        <ProfileWrapper>
          <Box>
            <FaQuestion size="32px" onClick={handleQWikiClick} />
          </Box>
        </ProfileWrapper>

        <OverlayTrigger
          trigger="click"
          show={showProfilePopup}
          onToggle={() => setShowProfilePopup(prevState => !prevState)}
          rootClose
          placement="bottom"
          overlay={profilePopover}
        >
          <ProfileWrapper>
            {source ? <Avatar alt="avatar" src={source} /> : <DefaultAvatar initials={initials} />}
            <Box ml="default" fontWeight="bold">
              {user.displayName}
            </Box>
          </ProfileWrapper>
        </OverlayTrigger>
      </Box>
    </Box>
  );
}
