import { FC, ReactElement } from 'react';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';

import { styled, themeGet } from '@qwealth/qcore';
import { S3Item } from '@qwealth/qvault';

import { ShortCode } from 'data/models/Compliance';

const fileNameCharacterLimit = 50;

const RelatedDocument = styled.button`
  width: 100%;
  background-color: ${themeGet('colors.gray.4')};
  padding: ${themeGet('space.large')};
  border-radius: ${themeGet('radii.default')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.default')};
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${themeGet('space.default')};
  border: none;

  &:hover {
    color: ${themeGet('colors.primary')};
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

interface PdfDocumentButtonProps {
  setPdfToDisplay: (value: React.SetStateAction<S3Item | undefined>) => void;
  changeTo: (changeTo: ShortCode) => void;
  pathToFolderLength: number;
  pdf: S3Item;
}

const PdfDocumentButton: FC<PdfDocumentButtonProps> = ({
  setPdfToDisplay,
  changeTo,
  pathToFolderLength,
  pdf,
}): ReactElement => {
  const fileName = pdf.key
    ? pdf.key.slice(pathToFolderLength, pdf.key.length)
    : 'Could not find file name';

  return (
    <RelatedDocument
      onClick={() => {
        setPdfToDisplay(pdf);
        changeTo('PDFViewer');
      }}
    >
      <BsFileEarmarkPdfFill size={32} color="black" />
      {fileName.length > 50 ? fileName.slice(0, fileNameCharacterLimit) + '...' : fileName}
    </RelatedDocument>
  );
};

export default PdfDocumentButton;
